import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { initAccount } from '@/service/account';
import { purviewRoutes } from '@/views/purview/routes'
import { clientRoutes } from '@/views/client/routes'
import { bureauRoutes } from '@/views/bureau/routes'
import { companyRoutes } from '@/views/company/routes'
import { operationRoutes } from '@/views/operation/routes'
import { qunzhiRoutes } from "@/views/qunzhi/routes"
import { xjsRoutes } from "@/views/xjs/routes"
import { operationManagementRoutes } from '@/views/operationManagement/routes'


Vue.use(VueRouter)

const authTokenGuard = (to, from, next) => {
  let uesr = store.state.loginUser;
  console.log('authTokenGuard', uesr);
  if (uesr && uesr.token) {
    next();
  } else {
    next('/home');
  }
};
const authCompGuard = (to, from, next) => {
  let logedUser = store.state.loginUser;
  console.log('authCompGuard', logedUser);
  if (logedUser && logedUser.token && logedUser.user) {
    next();
  } else {
    next('/home');
  }
};
const routes = [
  {
    path: '/',
    redirect: '/center',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.loginUser && store.state.loginUser.user) {
            next('/center')
          } else {
            next();
          }
        }
      },
      {
        path: 'center',
        component: () => import('@/views/center/Center.vue'),
        beforeEnter: authCompGuard
      },
      // 基础配置（人员权限、菜单配置、字典等）
      {
        path: 'purview',
        component: () => import('@/views/purview/Index.vue'),
        children: purviewRoutes,
        beforeEnter: authCompGuard
      },
      // 客户管理
      {
        path: 'client',
        component: () => import('@/views/client/Index.vue'),
        children: clientRoutes,
        beforeEnter: authCompGuard
      },
      // 统计局看板
      {
        path: 'bureau',
        component: () => import('@/views/bureau/Index.vue'),
        children: bureauRoutes,
        beforeEnter: authCompGuard
      },
      // 企业申请
      {
        path: 'company',
        component: () => import('@/views/company/Index.vue'),
        children: companyRoutes,
        beforeEnter: authCompGuard
      },
      // 物流运营
      {
        path: 'operationManagement',
        component: () => import('@/views/operationManagement/Index.vue'),
        children: operationManagementRoutes,
        beforeEnter: authCompGuard
      },
      // 门户管理
      // {
      //   path: 'portal',
      //   component: () => import('@/views/portal/Index.vue'),
      //   beforeEnter: authCompGuard
      // },
      // 门户管理-网站运营
      {
        path: 'portal',
        component: () => import('@/views/operation/Index.vue'),
        children: operationRoutes,
        beforeEnter: authCompGuard
      },

      {
        path: 'qunzhi',
        component: () => import('@/views/qunzhi/Index.vue'),
        children: qunzhiRoutes,
        beforeEnter: authCompGuard
      },
      {
        path: 'xjs',
        component: () => import('@/views/xjs/Index.vue'),
        children: xjsRoutes,
        beforeEnter: authCompGuard
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 初始化登陆态
  initAccount().then(() => {
    next();
  });
});
export default router
