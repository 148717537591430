/**
 * 设计公司模块路由
 */
export const operationManagementRoutes = [
  //运营分配
  {
    path: 'waybillManage',
    component: () => import('@/views/operationManagement/waybillManage/WaybillManage.vue')
  },
  {
    path: 'adminCategoryManagement',
    component: () => import('@/views/operationManagement/adminCategoryManagement/index.vue')
  },
  {
    path: 'announcementManagement',
    component: () => import('@/views/operationManagement/announcementManagement/index.vue')
  },
  {
    path: 'informationNews',
    component: () => import('@/views/operationManagement/informationNews/index.vue')
  },
  {
    path: 'informationNotice',
    component: () => import('@/views/operationManagement/informationNotice/index.vue')
  },
  {
    path: 'reportManagement',
    component: () => import('@/views/operationManagement/reportManagement/index.vue')
  },
]