import localForage from 'localforage';
import _ from 'lodash'
import store from '@/store'
import router from '@/router'
import { postToServeServer } from './common';
/**
 * 从本地存储初始化登陆信息
 */
export async function initAccount() {
    let user = await localForage.getItem('logedUser');
    // console.log(user);
    store.commit('setLogedUser', user);
}
/**
 * 获取生成的菜单列表
 * @param {*} obj 
 */
export async function navBuildNav(obj){
    return await postToServeServer('/nav/build-nav', obj);
}
/**
 * 修改个人信息
 */
export async function modConfig(obj){
    obj.user_id=store.state.loginUser.user.id;
    await modUserInfo(obj);
    let logedUser = await localForage.getItem('logedUser');
    let getLogedUser = await getLogedUserInfo();
    Object.assign(logedUser.user,getLogedUser);
    await store.dispatch('setLogedUser', logedUser);
    // await localForage.setItem('logedUser', logedUser);
    // store.commit('setLogedUser', logedUser);
}
/**
 * 修改指定用户信息
 * @param {*} obj 
 */
export async function modUserInfo(obj){
    return await postToServeServer('/my/set-myinfo', obj);
}
/**
 * 修改密码
 * @param {*} old_password 
 * @param {*} password
 * @param {*} cpassword 
 */
export async function modPwd(old_password,password,cpassword){
    let obj={
        user_id:store.state.loginUser.user.id,
        old_passwd:old_password,
        new_passwd:password,
        new_passwd_confirmed:cpassword
    }
    await postToServeServer('/my/set-password', obj);
}
/**
 * 修改密码-验证旧密码是否正确
 */
export async function modPwdAgo(old_password){
    await postToServeServer('/my-company/verify-my-password', {passwd:old_password});
}
/**
 * 清除登陆信息,回到首页
 */
export async function logOut(){
    await store.dispatch('setLogedUser', null);
    // await localForage.setItem('logedUser',null);
    // store.commit('setLogedUser', null);
    router.push('/home');
}
/**
 * 获得当前登陆用户信息
 */
export async function getLogedUserInfo(){
    let userInfo = await postToServeServer('/my/get-myinfo', {});
    if (userInfo.id) {
        return {
            //  ⽤户ID
            id: userInfo.id,
            //  登录⼿机号
            mobile: userInfo.mobile,
            //  姓名
            name: userInfo.name,
            //  性别 0未知,1男,2⼥
            gender: userInfo.gender,
            //  头像地址
            avatar: userInfo.avatar,
            //  个⼈介绍
            intro: userInfo.intro,
            //  电话
            phone: userInfo.phone,
            //  座机
            tel: userInfo.tel,
            //  邮箱
            mail: userInfo.mail,
            //  微信
            wechat: userInfo.wechat,
            //  号码
            qq: userInfo.qq,
            //  注册进度 0⼿机号⽅式注册，完成了⼿机号验证。
            //  1⼿机号⽅式注册，完成
            // 了基本信息和密码。
            // 10邀请⽅式注册，完成了
            // 邀请信息验证。
            // 2注册完成。
            step: userInfo.step,
            //  所属公司ID
            comp_id: userInfo.comp_id
        }
    }
    return null;
}
/**
 * 手机号+密码登陆
 * @param {*} _obj 
 */
export async function loginByMobileAndPwd(_obj) {
    let resp = await postToServeServer('/login/account-auth', {
        mobile: _obj.mobile,
        passwd: _obj.passwd,
        captcha: _obj.captcha,
        captcha_token: _obj.captchaToken,
        remember:_obj.remember,
    });
    let user = {
        user: resp.user,
        token: resp.token,
        comp:resp.comp
    };
    await store.dispatch('setLogedUser', user);
    // await localForage.setItem('logedUser', user);
    // store.commit('setLogedUser', user);
}
/**
 * 使用手机号登陆,并设置登陆态
 * @param {*} _obj 注册请求参数 
 * mobile ⼿机号码 
 * code ⼿机验证码 
 * captcha 验证码 
 * captcha_token 验证码token
 * @param {*} _this vue实例
 */
export async function loginByMobile(_obj, _this) {
    let resp = await postToServeServer('/login-by-mobile/auth', {
        mobile: _obj.mobile,
        code: _obj.code,
        captcha: _obj.captcha,
        captcha_token: _obj.captcha_token
    });
    let user = {
        user: resp.user,
        token: resp.token,
        comp:resp.comp
    };
    await store.dispatch('setLogedUser', user);
    // await localForage.setItem('logedUser', user);
    // store.commit('setLogedUser', user);
    _this.$router.push('/center');
}
/**
 * 获取邀请码
 */
export async function genInviteCode(){
    return await postToServeServer('/my-company/create-invite-code');
}
/**
 * 发送邀请码
 * @param {*} invite_code 邀请码
 * @param {*} phones ⼿机号列表,多个⼿机号⽤英⽂逗号隔开
 */
export async function sendInviteCode(invite_code,phones){
    return await postToServeServer('/my-company/send-invite',{
        invite_code:invite_code,
        mobiles:phones
    });
}