/**
 * 设计公司模块路由
 */
export const clientRoutes = [
  // 客户管理
  {
    path: 'khgl',
    component: () => import('@/views/client/Khgl.vue')
  },
  // 客户标签
  {
    path: 'tags',
    component: () => import('@/views/client/tags/Tags.vue')
  },
  // 新增用户
  {
    path: 'addUser',
    component: () => import('@/views/client/AddUser.vue')
  },
  // 短信模板
  {
    path: 'smsTemplate',
    component: () => import('@/views/client/template/SmsTemplate.vue')
  },
  // 添加邮件模板
  {
    path: 'editMail',
    component: () => import('@/views/client/template/EditMail.vue')
  },
  // 邮件模板
  {
    path: 'mailTemplate',
    component: () => import('@/views/client/template/MailTemplate.vue')
  },
  // 短信推送
  {
    path: 'smsPush',
    component: () => import('@/views/client/push/SmsPush.vue')
  },
  // 邮件推送
  {
    path: 'mailPush',
    component: () => import('@/views/client/push/MailPush.vue')
  },
  // 运营日志
  {
    path: 'log',
    component: () => import('@/views/client/log/Log.vue')
  },
  // 运营资质申请
  {
    path: 'apply',
    component: () => import('@/views/client/Apply.vue')
  },
  // 工厂资质代传
  {
    path: 'qualSubmit',
    component: () => import('@/views/client/QualSubmit.vue')
  },
  // 客户注册
  {
    path: 'register',
    component: () => import('@/views/client/Register.vue')
  },
  // 企业入规管理
  {
    path: 'ruGui',
    component: () => import('@/views/client/ruGui/RuGui.vue')
  },
]