let URLS = {
    /**
     * 发布到腾讯云
     */
    "tencent": {
        SERVER_API: 'https://pay.zt.emergen.cn/api/adminnode/ac/v1',
        SERVER_USER_API: 'https://pay.zt.emergen.cn/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'https://pay.zt.emergen.cn/api/usercenter/pc/v1',
        BASEINFO1_API: 'https://pay.zt.emergen.cn/api/usercenter/uc-adm/v1',
        FACTORY_API: 'https://pay.zt.emergen.cn/api/usercenter/pc-adm/v1',
        SHARE_API: 'https://pay.zt.emergen.cn/api/fx/fx-admin-s2c/v1',
        CRM_API: 'https://pay.zt.emergen.cn/api/crm/crm/v1',
        GOV_API: 'https://pay.zt.emergen.cn/api/gov/api',
        BUREAN_API: 'https://pay.zt.emergen.cn/api/tjj/tjj/v1',
        BASEINFO_API: 'https://pay.zt.emergen.cn/api/baseinfo/api',
        PROP_API: 'https://pay.zt.emergen.cn/api/prop/api',
        FILE_API: "https://pay.zt.emergen.cn/api/oss/api",
        PORTAL_URL: "https://usercenter.zt.emergen.cn/",
        OPERATION_API: "https://pay.zt.emergen.cn/api/portalserver",
        IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/prod/",
        MES_API: "https://gateway.mes.emergen.cn/api",
        XJS_URL: "https://consul.mes.emergen.cn:31082/#/",
        OTHER_API: "http://mespath.nk001.com/api",
    },
    /**
     * 发布到腾讯云集群
     */
    "tencent_k8s": {
        WLBASE_URL: "http://blcapi.nk001.com",
        SERVER_API: 'http://ztadminnode.nk001.com/ac/v1',
        SERVER_USER_API: 'http://ztusercenter.nk001.com/uc/v1',
        SERVER_ITEM_API: 'http://ztusercenter.nk001.com/pc/v1',
        BASEINFO1_API: 'http://ztusercenter.nk001.com/uc-adm/v1',
        FACTORY_API: 'http://ztusercenter.nk001.com/pc-adm/v1',
        SHARE_API: 'http://ztapifx.nk001.com/fx-admin-s2c/v1',
        CRM_API: 'http://ztcrm.nk001.com/crm/v1',
        GOV_API: 'http://ztgov.nk001.com/api',
        BUREAN_API: 'http://ztapitjj.nk001.com/tjj/v1',
        BASEINFO_API: 'http://ztbaseinfo.nk001.com/api',
        PROP_API: 'http://ztprop.nk001.com/api',
        FILE_API: "http://ztoss.nk001.com/api",
        OPERATION_API: "http://ztportalserver.nk001.com",
        PORTAL_URL: "https://ztusercenter.nk001.com/",
        IMG_URL_PRE: "https://zt.cdn.nk001.com/wky/",
        MES_API: "http://gateway.nk001.com/api",
        XJS_URL: "https://report.nk001.com/zc/#/",
        OTHER_API: "http://cen.nk001.com/api",
    },
    /**
     * 发布到微控云
     */
    "wky": {
        SERVER_API: 'http://zts.zc.emergen.cn:3050/api/adminnode/ac/v1',
        SERVER_USER_API: 'http://zts.zc.emergen.cn:3050/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'http://zts.zc.emergen.cn:3050/api/usercenter/pc/v1',
        BASEINFO1_API: 'http://zts.zc.emergen.cn:3050/api/usercenter/uc-adm/v1',
        FACTORY_API: 'http://zts.zc.emergen.cn:3050/api/usercenter/pc-adm/v1',
        SHARE_API: 'http://zts.zc.emergen.cn:3050/api/fx/fx-admin-s2c/v1',
        CRM_API: 'http://zts.zc.emergen.cn:3050/api/crm/crm/v1',
        GOV_API: 'http://zts.zc.emergen.cn:3050/api/gov/api',
        BUREAN_API: 'http://zts.zc.emergen.cn:3050/api/tjj/tjj/v1',
        BASEINFO_API: 'http://zts.zc.emergen.cn:3050/api/baseinfo/api',
        PROP_API: 'http://zts.zc.emergen.cn:3050/api/prop/api',
        FILE_API: "http://zts.zc.emergen.cn:3050/api/oss/api",
        OPERATION_API: "http://zts.zc.emergen.cn:3050/api/portalserver/",
        PORTAL_URL: "https://usercenter.zt.emergen.cn/",
        IMG_URL_PRE: "https://zt.cdn.nk001.com/wky/",
        MES_API: "http://mga.zc.emergen.cn:3050",
        XJS_URL: "https://consul.mes.emergen.cn:31082/#/",
        OTHER_API: "http://mespath.nk001.com/api",
    },
    /**
     * 发布到余姚测试
     */
    "test": {
        WLBASE_URL: "https://consul.mes.emergen.cn:5004",
        SERVER_API: 'https://test.emergen.cn/api/adminnode/ac/v1',
        SERVER_USER_API: 'https://test.emergen.cn/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'https://test.emergen.cn/api/usercenter/pc/v1',
        BASEINFO1_API: 'https://test.emergen.cn/api/usercenter/uc-adm/v1',
        FACTORY_API: 'https://test.emergen.cn/api/usercenter/pc-adm/v1',
        SHARE_API: 'https://test.emergen.cn/api/fx/fx-admin-s2c/v1',
        CRM_API: 'https://test.emergen.cn/api/crm/crm/v1',
        GOV_API: 'https://test.emergen.cn/api/gov/api',
        BUREAN_API: 'https://test.emergen.cn/api/tjj/tjj/v1',
        BASEINFO_API: 'https://test.emergen.cn/api/baseinfo/api',
        PROP_API: 'https://test.emergen.cn/api/prop/api',
        FILE_API: "https://test.emergen.cn/api/oss/api",
        PORTAL_URL: "https://test.home.emergen.cn/admin",
        OPERATION_API: "https://test.emergen.cn/api/portalserver",
        IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/test/",
        MES_URL: "http://demo.emergen.cn:8200/",
        MES_API: "https://test.emergen.cn/gateway/api",
        XJS_URL: "https://consul.mes.emergen.cn:31082/#/",
        OTHER_API: "http://mespath.nk001.com/api",
    },
    /**
     * 发布到7004开发联调
     */
    "7004": {
        WLBASE_URL: "https://consul.mes.emergen.cn:6012",
        SERVER_API: 'http://zd-admin.httpday.com/ac/v1',
        SERVER_ITEM_API: 'http://demo.emergen.cn:8013/pc/v1',
        BASEINFO1_API: 'http://demo.emergen.cn:8013/uc-adm/v1',
        CRM_API: 'http://demo.emergen.cn:8017/crm/v1',
        GOV_API: 'http://demo.emergen.cn:4007/api',
        BUREAN_API: 'http://demo.emergen.cn:8027/tjj/v1',
        BASEINFO_API: 'http://demo.emergen.cn:4011/api',
        PROP_API: 'http://demo.emergen.cn:4013/api',
        FILE_API: "http://demo.emergen.cn:4015/api",
        IMG_URL_PRE: "http://cdn.file.nk.emergen.cn/dev/"
    },
    "development": {
        WLBASE_URL: "http://blcapi.nk001.com",
        SERVER_API: 'http://ztadminnode.nk001.com/ac/v1',
        SERVER_USER_API: 'http://ztusercenter.nk001.com/uc/v1',
        SERVER_ITEM_API: 'http://ztusercenter.nk001.com/pc/v1',
        BASEINFO1_API: 'http://ztusercenter.nk001.com/uc-adm/v1',
        FACTORY_API: 'http://ztusercenter.nk001.com/pc-adm/v1',
        SHARE_API: 'http://ztapifx.nk001.com/fx-admin-s2c/v1',
        CRM_API: 'http://ztcrm.nk001.com/crm/v1',
        GOV_API: 'http://ztgov.nk001.com/api',
        BUREAN_API: 'http://ztapitjj.nk001.com/tjj/v1',
        BASEINFO_API: 'http://ztbaseinfo.nk001.com/api',
        PROP_API: 'http://ztprop.nk001.com/api',
        FILE_API: "http://ztoss.nk001.com/api",
        OPERATION_API: "http://ztportalserver.nk001.com",
        PORTAL_URL: "https://ztusercenter.nk001.com/",
        IMG_URL_PRE: "https://zt.cdn.nk001.com/wky/",
        MES_API: "http://gateway.nk001.com/api",
        XJS_URL: "https://report.nk001.com/zc/#/",
        OTHER_API: "http://120.203.181.235:5052/api",
    }
}
// console.log("process.env.VUE_APP_TITLE",process);
/**
 * admin管理基础平台api
 */
export const SERVER_API = URLS[process.env.VUE_APP_TITLE].SERVER_API;
/**
 * 用户管理api (postToUserServer)
 */
export const SERVER_USER_API = URLS[process.env.VUE_APP_TITLE].SERVER_USER_API;
/**
 * 商品交易api (postToItemServer)
 */
export const SERVER_ITEM_API = URLS[process.env.VUE_APP_TITLE].SERVER_ITEM_API;
/**
 * 文件服务api
 */
export const FILE_API = URLS[process.env.VUE_APP_TITLE].FILE_API;
/**
 * 图片服务
 */
export const IMG_URL_PRE = URLS[process.env.VUE_APP_TITLE].IMG_URL_PRE;
/**
 * 产品相关类目字典api
 */
export const BASEINFO_API = URLS[process.env.VUE_APP_TITLE].BASEINFO_API;
/**
 * 产品相关资质工艺字典api
 */
export const BASEINFO1_API = URLS[process.env.VUE_APP_TITLE].BASEINFO1_API;
/**
 * CRM相关api
 */
export const CRM_API = URLS[process.env.VUE_APP_TITLE].CRM_API;
/**
 * 政府政策api (postToGovServer)
 */
export const GOV_API = URLS[process.env.VUE_APP_TITLE].GOV_API;
/**
 * 统计局看板api (postToGovServer)
 */
export const BUREAN_API = URLS[process.env.VUE_APP_TITLE].BUREAN_API;
/**
 * 工厂看板api (postToGovFactory)
 */
export const FACTORY_API = URLS[process.env.VUE_APP_TITLE].FACTORY_API;
/**
 * 备料调研问卷api (postToPropServer)
 */
export const PROP_API = URLS[process.env.VUE_APP_TITLE].PROP_API;
/**
 * 销售公司分销审核api (postToShareServer)
 */
export const SHARE_API = URLS[process.env.VUE_APP_TITLE].SHARE_API;
/**
 * 门户前端url
 */
export const PORTAL_URL = URLS[process.env.VUE_APP_TITLE].PORTAL_URL;
/**
 * 门户-网站运营
 */
export const OPERATION_API = URLS[process.env.VUE_APP_TITLE].OPERATION_API;
/**
 * wl url
 */
export const WLBASE_URL = URLS[process.env.VUE_APP_TITLE].WLBASE_URL;

/**
 * Mes url
 */
export const MES_API = URLS[process.env.VUE_APP_TITLE].MES_API;
/**
 * 当前域名
 */
export const BASE_URL = `http://${window.location.host}`;

export const XJS_URL = URLS[process.env.VUE_APP_TITLE].XJS_URL

export const OTHER_API = URLS[process.env.VUE_APP_TITLE].OTHER_API