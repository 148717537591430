import axios from 'axios'
import store from '@/store'
import { 
    SERVER_API, 
    SERVER_ITEM_API, 
    BASEINFO_API,
    BASEINFO1_API,
    SHARE_API,
    FILE_API,
    IMG_URL_PRE,
    CRM_API,
    GOV_API,
    PROP_API,
    FACTORY_API,
    BUREAN_API,
    OPERATION_API,
    MES_API,
    OTHER_API
} from '@/config'


/**
 * 发送请求到用户服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
function _postServer(api, url, data, config = {}) {console.log(999,data);
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser=store.state.loginUser;
        config.headers = Object.assign({}, config.headers, { 
            token: loginUser.token,
            user_id:loginUser.user && loginUser.user.id,
            comp_id:loginUser.user && loginUser.user.comp_id
        });
    }
    return axios.post(api + url, data, config).then(resp => {
        console.log(resp);
        switch (resp.data.errcode) {
            case 0:
                return resp.data.result;
            case 2:
                return store.dispatch('setLogedUser', null);
            default:
                throw new Error(resp.data.message);
        }
    });
}
/**
 * 发送请求到mes服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
 function _postMes(api, url, data, config = {}) {
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser=store.state.loginUser;
        config.headers = Object.assign({}, config.headers, { 
            Authorization:loginUser.token,
            token: loginUser.token,
            user_id:loginUser.user && loginUser.user.id,
            comp_id:loginUser.user && loginUser.user.comp_id
        });
    }
    return axios.post(api + url, data, config).then(resp => {
        console.log(resp);
        return resp.data;
    });
}
/**
 * 发送请求到用户服务器  (专用于门户)
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
 function _postPortalServer(api, url, data, config = {}) {console.log(999,data);
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser=store.state.loginUser;
        config.headers = Object.assign({}, config.headers, { 
            token: loginUser.token,
            user_id:loginUser.user && loginUser.user.id,
            comp_id:loginUser.user && loginUser.user.comp_id
        });
    }
    return axios.post(api + url, data, config).then(resp => {
        console.log(resp);
        switch (resp.data.code) {
            case 200:
                return resp.data;
            case 403:
                return store.dispatch('setLogedUser', null);
            default:
                throw new Error(resp.data.msg);
        }
    });
}
/**
 * 发送请求到用户服务器   (专用于门户)
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
 async function _getPortalServer(api, url, data, config = {}) {console.log(999,data);
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser=store.state.loginUser;
        config.headers = Object.assign({}, config.headers, { 
            token: loginUser.token,
            user_id:loginUser.user && loginUser.user.id,
            comp_id:loginUser.user && loginUser.user.comp_id
        });
    }
    let params = { params: data };
    return axios.get(api + url, params, config).then(resp => {
        console.log(resp);
        switch (resp.data.code) {
            case 200:
                return resp.data;
            case 403:
                return store.dispatch('setLogedUser', null);
            default:
                throw new Error(resp.data.msg);
        }
    });
}
/**
 * 请求门户外包服务器（post）
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} config 
 */
 function postToPortalServer(url, data, config = {}) {
    return _postPortalServer(OPERATION_API, url, data, config);
}
/**
 * 请求门户外包服务器（get）
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} config 
 */
 function getToPortalServer(url, data, config = {}) {
    return _getPortalServer(OPERATION_API, url, data, config);
}
/**
 * 请求mes服务器
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} config 
 */
function postToMesServer(url, data, config = {}) {
    return _postMes(MES_API, url, data, config);
}
/**
 * 请求admin服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToServeServer(url, data, config = {}) {
    return _postServer(SERVER_API, url, data, config);
}
/**
 * 请求admin服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
 function postToItemServeServer(url, data, config = {}) {
    return _postServer(SERVER_ITEM_API, url, data, config);
}
/**
 * 请求类目字典服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToBaseInfoServer(url, data, config = {}) {
    return _postServer(BASEINFO_API, url, data, config);
}
/**
 * 请求资质工艺字典服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToBaseInfo1Server(url, data, config = {}) {
    return _postServer(BASEINFO1_API, url, data, config);
}
/**
 * 请求 CRM 服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToCrmServer(url, data, config = {}) {
    return _postServer(CRM_API, url, data, config);
}
/**
 * 请求政府服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToGovServer(url, data, config = {}) {
    return _postServer(GOV_API, url, data, config);
}
/**
 * 请求政府服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToFactoryServer(url, data, config = {}) {
    return _postServer(FACTORY_API, url, data, config);
}
/**
 * 请求统计局看板服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToBureanServer(url, data, config = {}) {
    return _postServer(BUREAN_API, url, data, config);
}
/**
 * 备料调研问卷
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToPropServer(url, data, config = {}) {
    return _postServer(PROP_API, url, data, config);
}
/**
 * 请求销售公司分销审核服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToShareServer(url, data, config = {}) {
    return _postServer(SHARE_API, url, data, config);
}
/**
 * 请求文件服务器api
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToFileServer(url, data, config = {}) {
    return _postServer(FILE_API, url, data, config);
}

/**
 *获得图片上传的url
 * @param {*} filename 文件名
 * @param {*} prefix 前缀
 * @param {*} suffix 后缀
 */
export function getUploadUrl(filename,prefix,suffix){
    return postToFileServer('/admin/upload_url',{filename:filename,prefix:prefix,suffix:suffix});
}

/**
 * 上传图片返回全路径
 * @param {*} file 文件对象
 * @param {*} prefix 前缀
 * @param {*} config axios配置
 * @param {*} filename 指定文件名
 */
async function uploadFile(file,prefix, config = {},filename) {
    let suffix = file.name.substr(file.name.lastIndexOf('.')+1);
    let uploadResp = await getUploadUrl(filename,prefix,suffix);
    console.log(uploadResp);
    let resp = await axios.put(uploadResp.url, file, config);
    return uploadResp.filename;
}
/**
 * 删除文件
 * @param {*} filename 
 */
export function delFile(filename) {
    return postToFileServer('/admin/del',{filename:filename.replace(IMG_URL_PRE, "")});
}
/**
 * 获取图形验证码
 */
function getCaptCha() {
    return postToServeServer('/create_captcha');
}
/**
 * 获取手机验证码
 * @param {*} mp 手机号
 */
function sendSmsCode(mp) {
    return postToServeServer('/register/get-mobile-code', { mobile: mp });
}


export function postToOtherServer(url, data, config = {}) {
    return axios.post(OTHER_API + url, data, config).then(resp => {
        return resp.data;
    });
}

export {  
    postToServeServer,
    postToItemServeServer,
    postToBaseInfoServer,
    postToBaseInfo1Server,
    postToShareServer,
    postToCrmServer, 
    getCaptCha, 
    sendSmsCode, 
    uploadFile,
    postToGovServer,
    postToPropServer,
    postToFactoryServer,
    postToBureanServer,
    postToMesServer,
    postToPortalServer,
    getToPortalServer
}