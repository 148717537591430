/**
 * 设计公司模块路由
 */
export const companyRoutes = [
  // 分销申请
  {
    path: 'shareApply',
    component: () => import('@/views/company/sharer/ShareApply.vue')
  },
  // 企业入规详情
  // {
  //   path: 'sharer/detail/:id',
  //   component: () => import('@/views/company/sharer/ShareDetail.vue')
  // },

  // 企业数据
  {
    path: 'compData',
    component: () => import('@/views/company/compData/CompData.vue')
  },
  // 企业数据配置
  {
    path: 'compDataConfig',
    component: () => import('@/views/company/compDataConfig/CompDataConfig.vue')
  },
  // 企业授权操作管理
  {
    path: 'compAuthor',
    component: () => import('@/views/company/compAuthor/CompAuthor.vue')
  },
]