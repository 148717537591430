/*
 * @Descripttion:
 * @version:
 * @Author: zhangfei
 * @Date: 2020-11-25 11:39:26
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-11-26 10:29:57
 */
export const operationRoutes = [
  // 交易集散中心
  {
    path: 'tradCenter',
    component: () => import('@/views/operation/tradCenter/tradCenter.vue')
  },
  // 创新设计中心
  {
    path: 'createCenter',
    component: () => import('@/views/operation/createCenter/createCenter.vue')
  },
]
