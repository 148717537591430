import Vue from 'vue'
import Vuex from 'vuex'
import localForage from 'localforage';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser:null,
  },
  mutations: {
    setLogedUser (state, user) {
      state.loginUser = user;
    },
  },
  actions: {
    async setLogedUser({ commit },user) {
      await localForage.setItem('logedUser', user);
      commit('setLogedUser', user);
    }
  },
  modules: {
  }
})
