/**
 * 设计公司模块路由
 */
export const bureauRoutes = [
  // 统计局看板
  {
    path: 'bureau',
    component: () => import('@/views/bureau/Bureau.vue')
  },
  // 企业入规审批列表
  {
    path: 'approve',
    component: () => import('@/views/bureau/approve/Approve.vue')
  },
  // 企业入规详情
  {
    path: 'approve/detail/:id',
    component: () => import('@/views/bureau/approve/QyrgDetail.vue')
  },
  // 数据看板
  {
    path: 'dataBoart',
    component: () => import('@/views/bureau/DataBoart.vue')
  },
  // 家具工厂
  {
    path: 'furniture',
    component: () => import('@/views/bureau/Furniture.vue')
  },
  // 数据看板
  {
    path: 'survey',
    component: () => import('@/views/bureau/Survey.vue')
  },
]