/**
 * 设计公司模块路由
 */
export const purviewRoutes = [
  // 配置权限代码
  {
    path: 'qxSet',
    component: () => import('@/views/purview/qxSet/QxSet.vue')
  },
  // 菜单管理
  {
    path: 'navs',
    component: () => import('@/views/purview/navs/Navs.vue')
  },
  // 角色管理
  {
    path: 'role',
    component: () => import('@/views/purview/role/Role.vue')
  },
  // 管理员管理
  {
    path: 'manager',
    component: () => import('@/views/purview/manager/Manager.vue')
  },
  // 产品类目属性字典
  {
    path: 'lmzd',
    component: () => import('@/views/purview/dictionaries/Lmzd.vue')
  },
  // 产品销售属性字典
  {
    path: 'xszd',
    component: () => import('@/views/purview/dictionaries/Xszd.vue')
  },
  // 产品资质字典
  {
    path: 'aptitude',
    component: () => import('@/views/purview/dictionaries/aptitude/Aptitude.vue')
  },
  // 产品工艺能力字典
  {
    path: 'craft',
    component: () => import('@/views/purview/dictionaries/craft/Craft.vue')
  },
  // 赣州聚集区字典
  {
    path: 'zone',
    component: () => import('@/views/purview/dictionaries/zone/Zone.vue')
  },
  // 注册企业管理
  {
    path: 'zcqy',
    component: () => import('@/views/purview/zcqy/Zcqy.vue')
  },
  // 注册企业管理-审核材料查看
  {
    path: 'audit',
    component: () => import('@/views/purview/zcqy/Audit.vue')
  },
  // 注册企业管理-公司资质材料
  {
    path: 'qual',
    component: () => import('@/views/purview/zcqy/Qual.vue')
  },
  // 注册企业管理-员工和权限查看
  {
    path: 'authority',
    component: () => import('@/views/purview/zcqy/Authority.vue')
  },
  // 注册企业管理-员工和权限查看-邀请同事
  {
    path: 'invite',
    component: () => import('@/views/purview/zcqy/Invite.vue')
  },
  // 产品资料管理
  {
    path: 'zlgl',
    component: () => import('@/views/purview/zlgl/Zlgl.vue')
  },
  // 产品资料管理-创建新单品
  {
    path: 'submitPro',
    component: () => import('@/views/purview/zlgl/SubmitPro.vue')
  },
  // 产品资料管理-创建附件
  {
    path: 'submitFile',
    component: () => import('@/views/purview/zlgl/SubmitFile.vue')
  },
  // 产品资料管理-打样管理
  {
    path: 'drawDesign',
    component: () => import('@/views/purview/zlgl/DrawDesign.vue')
  },
  // 运营人工审批
  {
    path: 'rgsp',
    component: () => import('@/views/purview/Rgsp.vue')
  },
  // 运营后台自动审批
  {
    path: 'zdsp',
    component: () => import('@/views/purview/Zdsp.vue')
  },
]